<template>
  <div class="room-modal-item">
    <div class="room-modal-item-imagearea">
      <div class="room-modal-item-image"><img :src="roomData.photo_url" /></div>
    </div>
    <div class="room-modal-item-infoarea">
      <div class="mb-3">
        <p class="text-lg bold">{{ roomData.name }}</p>
        <div v-if="isDiscounted" class="d-flex items-center">
          <p class="multinight-discount-label">連泊割</p>
          <p class="text-sub">
            チケット {{ roomData.standard_ticket_amount }}枚→
            <span class="text-red">{{ roomData.total_ticket_amount }}枚</span>
          </p>
        </div>
        <div v-else>
          <p class="text-sub">チケット {{ roomData.total_ticket_amount }}枚</p>
        </div>
      </div>
      <ul class="room-modal-item-info">
        <li>
          <icon-door></icon-door>
          <p>{{ roomData.kind_label }}（{{ roomData.capacity_label }}）</p>
          <p v-if="roomData.kind_sex_label" class="room-modal-item-info-label">
            {{ roomData.kind_sex_label }}
          </p>
        </li>
        <li>
          <icon-bed></icon-bed>
          <p>{{ roomData.bed_labels.join(', ') }}</p>
        </li>
        <li v-if="roomData.desk_chair_info">
          <icon-desk></icon-desk>
          <p>{{ roomData.desk_chair_info }}</p>
        </li>
        <li v-if="roomData.is_popular">
          <icon-calendar></icon-calendar>
          <p>
            予約の埋まりやすい部屋
            <a :href="popularRoomInfoUrl" target="_blank" rel="noopener">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 26 26"
                fill="none"
                stroke="#CF761D"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                <line x1="12" y1="17" x2="12.01" y2="17"></line>
              </svg>
            </a>
          </p>
        </li>
      </ul>
      <div v-if="roomData.notice" class="text-xs mt-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 25 25"
          fill="none"
          stroke="#950008"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="12" y1="8" x2="12" y2="12"></line>
          <line x1="12" y1="16" x2="12.01" y2="16"></line>
        </svg>
        {{ roomData.notice }}
      </div>
    </div>
    <div class="room-modal-item-btnarea text-center">
      <button v-if="isReservable" class="btn btn-primary" @click.prevent="$emit('select', roomData)">確定</button>
      <span v-else class="btn btn-disable">チケットが足りません</span>
    </div>
  </div>
</template>

<script>
import IconBed from '../../../shared/icons/bed.vue'
import IconCalendar from '../../../shared/icons/calendar.vue'
import IconDoor from '../../../shared/icons/door.vue'
import IconDesk from '../../../shared/icons/desk.vue'

export default {
  components: { IconBed, IconCalendar, IconDoor, IconDesk },
  props: {
    roomData: {
      type: Object,
      required: true,
    },
    popularRoomInfoUrl: {
      type: String,
      default: 'https://address.zendesk.com/hc/ja/articles/19926476239897',
    },
    remainingTicketAmount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    isDiscounted() {
      return this.roomData.standard_ticket_amount !== this.roomData.total_ticket_amount
    },
    isReservable() {
      return this.remainingTicketAmount >= this.roomData.total_ticket_amount
    },
  },
}
</script>
