<template>
  <div v-if="!isLoaded" class="modal-content loading-modal">
    <vue-loading type="spin" color="#CF761D" :size="{ width: '50px', height: '50px' }"></vue-loading>
  </div>
  <div v-else class="modal-content modal-room-reserve-calendar">
    <div class="modal-header relative" :class="{ 'd-none': !!!room.id }">
      <div class="modal-header-top mb-2">
        <p v-if="isLegacyPlan" class="lead pb-0">
          {{ actionText }}
          <span v-if="isSelectedDate">({{ stayDaysText }})</span>
        </p>
        <p v-else-if="!isSelectedDate || !isLoadedTickets" class="lead pb-0">{{ actionText }}</p>
        <p v-else-if="isDiscounted" class="lead pb-0">
          {{ stayDaysText }}（消化チケット
          <span style="text-decoration: line-through">{{ standardTicketAmount }}枚</span>
          →
          <span class="text-red">{{ totalTicketAmount }}枚</span>
          ）
        </p>
        <p v-else class="lead pb-0">{{ stayDaysText }}（消化チケット{{ totalTicketAmount }}枚）</p>
        <p v-if="isContracted" class="text-gray2 pb-0">{{ minNightsText }}</p>
      </div>
      <div>
        <div v-if="isContracted">
          <div class="flex items-center">
            <svg
              class="flex-shrink-0 mr-2"
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="17" cy="17" r="17" fill="#CF761D" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20.8093 8.83707C20.8093 8.37477 20.4345 8 19.9722 8C19.5099 8 19.1352 8.37477 19.1352 8.83707V9.55925H14.5723V8.83707C14.5723 8.37477 14.1975 8 13.7352 8C13.2729 8 12.8981 8.37477 12.8981 8.83707V9.55925H11.3963C10.0729 9.55925 9 10.6321 9 11.9556V15.0741V22.8703C9 24.1938 10.0729 25.2667 11.3963 25.2667H22.3111C23.6345 25.2667 24.7074 24.1938 24.7074 22.8703V15.0741V11.9556C24.7074 10.6321 23.6345 9.55925 22.3111 9.55925H20.8093V8.83707ZM23.0333 14.237V11.9556C23.0333 11.5567 22.7099 11.2334 22.3111 11.2334H20.8093V11.9556C20.8093 12.4179 20.4345 12.7926 19.9722 12.7926C19.5099 12.7926 19.1352 12.4179 19.1352 11.9556V11.2334H14.5723V11.9556C14.5723 12.4179 14.1975 12.7926 13.7352 12.7926C13.2729 12.7926 12.8981 12.4179 12.8981 11.9556V11.2334H11.3963C10.9975 11.2334 10.6741 11.5567 10.6741 11.9556V14.237H23.0333ZM10.6741 15.9111H23.0333V22.8703C23.0333 23.2692 22.7099 23.5925 22.3111 23.5925H11.3963C10.9975 23.5925 10.6741 23.2692 10.6741 22.8703V15.9111Z"
                fill="white"
              />
            </svg>
            <p class="text-sub lh-base">
              この部屋は現在
              <span class="text-bold">{{ reserveUntilText }}まで</span>
              予約枠を開放しています。
              <a :href="reserveLimitationHelpURL" class="text-primary underline" target="_blank" rel="noopener">詳細</a>
            </p>
          </div>
        </div>
      </div>
      <div v-if="discounts.length > 0 && !isLegacyPlan" class="modal-header-multinight-discount">
        <p class="multinight-discount-label">連泊割</p>
        <div>
          <div v-for="discount in discounts" :key="discount.start_date" class="d-flex items-center">
            <p class="multinight-discount-item">{{ discount.ticket_label }}</p>
            <p class="multinight-discount-date">{{ discount.period_label }}</p>
          </div>
        </div>
      </div>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <div class="text-right mb-2"><button class="text-gray2 underline" @click="clearSelection">日付をクリア</button></div>
      <div :class="{ 'd-none': !!!room.id }">
        <DateCalendarPicker
          ref="dateCalendarPicker"
          :start-date-value="startDateValue()"
          :end-date-value="endDateValue()"
          :start-date="calStartDate"
          :end-date="calEndDate"
          :disabled-dates="reservedDates"
          :holydays="holydays"
          :min-nights="minDays"
          always-visible
          @check-in-changed="checkInChanged"
          @check-out-changed="checkOutChanged"
        ></DateCalendarPicker>
      </div>

      <div v-if="!!!room.id" class="text-align-center py-5">
        <p class="text-alert pb-0">こちらのお部屋の空室カレンダーは閲覧できません</p>
        <p class="mt-5 pb-0"><a href="#" data-bs-dismiss="modal">閉じる</a></p>
      </div>

      <div v-if="isContracted">
        <p v-if="isLegacyPlan" class="text-center">{{ errorMsg }}</p>
      </div>
    </div>

    <div v-if="isMismatchSex && isMultiReserve" class="pb-5">
      <p class="text-center">1部屋目では予約のできない部屋です。</p>
    </div>

    <div v-else-if="isMismatchSex" class="pb-5">
      <p class="text-center">本アカウントでは予約のできない部屋です。</p>
    </div>
    <div v-else-if="isNotVerified && isContracted" class="modal-footer-change-plan-offer">
      <div>
        <a :href="userVerifyPath">
          <div>
            <p class="text-bold">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="mb-1"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="8" x2="12" y2="12"></line>
                <line x1="12" y1="16" x2="12.01" y2="16"></line>
              </svg>
              <span>未完了の手続きがあります。</span>
            </p>
            <p class="change-plan-offer-subtxt">すべてが完了すると家の予約が可能になります。</p>
          </div>
          <div class="change-plan-offer-btn">確認する</div>
        </a>
      </div>
    </div>

    <div v-else-if="needUpgrade" class="modal-footer-change-plan-offer">
      <div>
        <a :href="planChangePath">
          <div>
            <p class="text-bold">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="mb-1"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="8" x2="12" y2="12"></line>
                <line x1="12" y1="16" x2="12.01" y2="16"></line>
              </svg>
              <span>予約チケットが{{ leftTicketAmounts * -1 }}枚不足しています</span>
            </p>
            <p class="change-plan-offer-subtxt">プランをアップグレードするとチケットを増やせます</p>
          </div>
          <div class="change-plan-offer-btn">プラン変更</div>
        </a>
      </div>
    </div>

    <div v-else-if="isContracted" style="z-index: 999">
      <div class="modal-footer-room-reserve-calendar">
        <div v-if="consumeTicketAmounts === 0">
          <div class="ticket-amounts">
            <p class="text-sub">この部屋は予約チケットを消化しません</p>
          </div>
        </div>

        <div v-else-if="!isLegacyPlan">
          <div class="ticket-amounts">
            <p class="mr-4 pb-0">
              予約チケット残数
              <span class="ml-1 text-xl text-bold">{{ currentTicketAmounts }}</span>
              <span v-if="shouldShowTicketDiff">→</span>
              <span v-if="shouldShowTicketDiff" class="text-xl text-bold">{{ leftTicketAmounts }}</span>
            </p>
            <p v-if="errorMsg" class="text-xs text-red">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1rem"
                height="1rem"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#950008"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="8" x2="12" y2="12"></line>
                <line x1="12" y1="16" x2="12.01" y2="16"></line>
              </svg>
              {{ errorMsg }}
            </p>
          </div>
        </div>

        <div v-else-if="!!registrationTickets && registrationTickets.length > 1">
          <div class="d-flex">
            <p class="text-center mr-3 pb-0">この家に使えるチケット残数</p>
            <input id="check-tickets-3" type="checkbox" class="check-tickets-trigger" />
            <label class="check-tickets-btn" for="check-tickets-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#CF761D"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M6 9l6 6 6-6"></path>
              </svg>
            </label>
            <div class="tickets-datalist">
              <p class="text-bold">この家に使えるチケット残数</p>
              <ul class="text-sub mt-2 mb-2">
                <li v-for="registrationTicket in registrationTickets" :key="registrationTicket.id" class="flex">
                  <p class="text-gray2">{{ registrationTicket.period_str }}</p>
                  <p class="text-bold">{{ registrationTicket.tickets_str }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div v-else-if="!!registrationTickets && registrationTickets.length == 1">
          <p class="text-center mr-4 pb-0">
            この家に使えるチケット残数
            <span class="userinfo-days text-bold">{{ registrationTickets[0]['tickets_str'] }}</span>
          </p>
          <p v-if="!!upgradeOffer">
            <a
              :href="upgradeOffer.url + '?from=room-reserve-calender'"
              target="_blank"
              rel="noopener"
              class="btn-text arrow-r-orange"
            >
              {{ upgradeOffer.text }}
            </a>
          </p>
        </div>

        <div v-else>
          <p class="text-center mr-4 pb-0">
            この家に使えるチケット残数
            <span class="userinfo-days text-bold">なし</span>
          </p>
        </div>

        <button type="button" class="btn btn-primary" :disabled="!canSubmit" @click="reserveRoom">
          {{ submitBtnText }}
        </button>
      </div>
    </div>

    <div v-else class="pb-5">
      <p class="text-center mb-3">
        部屋の予約はサービスにお申し込みいただいた後に
        <br />
        可能になります。
      </p>
      <div class="text-center">
        <a href="/join?from=calbtn" class="btn btn-primary w-50">サービスに申込む</a>
      </div>
    </div>
  </div>
</template>

<script>
import DateCalendarPicker from 'vuejs/shared/components/DateCalendarPicker'
import axios from 'axios'
import dayjs from 'dayjs'
import _ from 'lodash'
import isBetween from 'dayjs/plugin/isBetween'
import timezone from 'dayjs/plugin/timezone'
import { VueLoading } from 'vue-loading-template'
import 'dayjs/locale/ja'

dayjs.extend(isBetween)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')
dayjs.locale('ja')

export default {
  components: { DateCalendarPicker, VueLoading },
  props: {
    defaultData: {
      type: Object,
      required: true,
    },
    room: {
      type: Object,
      required: true,
    },
    isContracted: {
      type: Boolean,
      required: true,
    },
    isNotVerified: {
      type: Boolean,
      required: true,
    },
    registrationTickets: {
      type: Array,
      default: () => [],
    },
    currentTicketAmounts: {
      type: Number,
      default: null,
    },
    upgradeOffer: {
      type: Object,
      default: () => {},
    },
    reservationPath: {
      type: String,
      required: true,
    },
    roomApiPath: {
      type: String,
      required: true,
    },
    submitBtnText: {
      type: String,
      default: '予約する',
    },
    selectedCheckInDate: {
      type: String,
      default: null,
    },
    selectedCheckOutDate: {
      type: String,
      default: null,
    },
    planChangePath: {
      type: String,
      required: true,
    },
    userVerifyPath: {
      type: String,
      required: true,
    },
    isUpgradeable: {
      type: Boolean,
      default: true,
    },
    isMismatchSex: {
      type: Boolean,
      default: false,
    },
    isMultiReserve: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      checkInDate: null,
      checkOutDate: null,
      isLoaded: false,
      errorMsg: '',
      reservedDates: [],
      calStartDate: null,
      calEndDate: null,
      reserveUntil: null,
      availableWeeks: null,
      reservablePeriod: null,
      holydays: [],
      minDays: 0,
      consumeTicketAmounts: null,
      reservationEndDate: null,
      reservableDate: null,
      discounts: [],
      standardTicketAmount: null,
      totalTicketAmount: null,
    }
  },
  computed: {
    reserveLimitationHelpURL() {
      return this.isContracted
        ? 'https://address-membersupport.zendesk.com/hc/ja/articles/15086006405913'
        : 'https://address.zendesk.com/hc/ja/articles/14723633927321'
    },
    actionText() {
      if (!this.checkInDate) {
        return '到着日を選択してください'
      }
      if (!this.checkOutDate) {
        return '出発日を選択してください'
      }
      return `${this.checkInDate.format('M月D日')}〜${this.checkOutDate.format('M月D日')}`
    },
    isSelectedDate() {
      return !!this.checkInDate && !!this.checkOutDate
    },
    canSubmit() {
      return this.isValidInput()
    },
    duration() {
      if (!this.checkInDate || !this.checkOutDate) return null

      return this.checkOutDate.diff(this.checkInDate, 'day')
    },
    stayingNightsText() {
      if (!!this.checkInDate && !!this.checkOutDate) {
        return `予約日数${this.duration}日`
      }
      return ''
    },
    minNightsText() {
      if (!!this.minDays && this.minDays > 1) {
        return ` ※最低予約日数${this.minDays}日`
      }
      return ''
    },
    reserveUntilText() {
      const useReservableDate = !this.reservationEndDate || this.reservationEndDate > this.reservableDate
      return this.reserveUntil + (useReservableDate ? `（${this.availableWeeks}週先）` : '')
    },
    leftTicketAmounts() {
      if (this.currentTicketAmounts >= 0) {
        return this.currentTicketAmounts - this.totalTicketAmount
      } else {
        return null
      }
    },
    isLegacyPlan() {
      return this.currentTicketAmounts === null
    },
    shouldShowTicketDiff() {
      if (this.currentTicketAmounts <= 0) return false
      if (this.leftTicketAmounts < 0) return false

      return this.currentTicketAmounts !== this.leftTicketAmounts
    },
    needUpgrade() {
      if (!this.isLegacyPlan) {
        return this.leftTicketAmounts < 0 && this.isUpgradeable
      } else {
        return false
      }
    },
    isDiscounted() {
      return this.totalTicketAmount !== this.standardTicketAmount
    },
    stayDaysText() {
      return `${this.duration}泊${this.duration + 1}日`
    },
    isLoadedTickets() {
      return !!this.totalTicketAmount && !!this.standardTicketAmount
    },
  },

  methods: {
    async fetchRoomDate() {
      await axios.get(this.roomApiPath).then((response) => {
        this.reservedDates = response.data.room.reserved_dates
        this.calStartDate = response.data.room.calendar_start_date
        this.calEndDate = response.data.room.calendar_end_date
        this.reserveUntil = response.data.room.reserve_until
        this.availableWeeks = response.data.room.available_weeks
        this.reservablePeriod = response.data.room.reservable_period
        this.holydays = response.data.room.holydays
        this.minDays = response.data.room.min_days
        this.consumeTicketAmounts = response.data.room.consume_ticket_amounts
        this.reservationEndDate = response.data.room.reservation_end_date
        this.reservableDate = response.data.room.reservable_date
        this.discounts = response.data.room.discounts

        if (this.isValidDates(this.selectedCheckInDate, this.selectedCheckOutDate)) {
          this.setCheckDates(this.selectedCheckInDate, this.selectedCheckOutDate)
        } else {
          this.setCheckDates(this.defaultData.check_in_date, this.defaultData.check_out_date)
        }

        this.isLoaded = true
      })
    },
    setCheckDates(checkInDate, checkOutDate) {
      if (this.isValidDates(checkInDate, checkOutDate)) {
        this.checkInDate = dayjs(checkInDate)
        this.checkOutDate = dayjs(checkOutDate)
      } else {
        this.clearSelection()
      }
    },
    startDateValue() {
      if (this.checkInDate !== null) {
        return this.checkInDate.format('YYYY/MM/DD')
      }
      return null
    },
    endDateValue() {
      if (this.checkOutDate !== null) {
        return this.checkOutDate.format('YYYY/MM/DD')
      }
      return null
    },
    checkInChanged(date) {
      this.checkInDate = date
      this.$emit('check-in-changed', date?.format('YYYY-MM-DD'))
      this.initializeTicketAmount()
    },
    checkOutChanged(date) {
      this.checkOutDate = date
      if (!date) {
        this.errorMsg = ''
      }
      this.fetchTicketAmount(this)
      this.$emit('check-out-changed', date?.format('YYYY-MM-DD'))
    },
    initializeTicketAmount() {
      this.standardTicketAmount = null
      this.totalTicketAmount = null
    },
    // ライブラリの都合でcheckOutChangedが何度も呼ばれるため、API通信は300msに一回のみにする
    fetchTicketAmount: _.debounce((vm) => {
      if (!vm.isSelectedDate || vm.isLegacyPlan) return
      const params = {
        check_in_date: vm.checkInDate?.format('YYYY-MM-DD'),
        check_out_date: vm.checkOutDate?.format('YYYY-MM-DD'),
      }
      axios.get(`${vm.roomApiPath}/calc_ticket_amount`, { params: params }).then((response) => {
        vm.standardTicketAmount = response.data.standard_ticket_amount
        vm.totalTicketAmount = response.data.total_ticket_amount
      })
    }, 300),
    reserveRoom() {
      const origin = new URL(window.location).origin
      const url = new URL(origin + this.reservationPath)
      url.searchParams.append('room_id', this.room.id)
      url.searchParams.append('check_in_date', this.checkInDate.format('YYYY-MM-DD'))
      url.searchParams.append('check_out_date', this.checkOutDate.format('YYYY-MM-DD'))
      window.location.href = url.href
    },
    clearSelection() {
      this.checkInDate = null
      this.checkOutDate = null
      if (this.$refs.dateCalendarPicker) {
        this.$refs.dateCalendarPicker.clearSelection()
      }
    },
    isValidDates(startDate, endDate) {
      endDate = dayjs(endDate).subtract(1, 'day').format('YYYY-MM-DD')
      if (!this.isValidDate(startDate) || !this.isValidDate(endDate)) {
        return false
      }
      let startDay = dayjs(startDate)
      let endDay = dayjs(endDate)

      while (startDay.isBefore(endDay)) {
        if (this.holydays.includes(parseInt(startDay.format('d')))) {
          return false
        }
        startDay = startDay.add(1, 'day')
      }

      return true
    },
    isValidDate(date) {
      if (!date) {
        return false
      }

      const day = dayjs(date)
      const calStartDate = dayjs(this.calStartDate)
      const calEndDate = dayjs(this.calEndDate)

      if (!day.isBetween(calStartDate.subtract(1, 'day'), calEndDate.add(1, 'day'))) {
        return false
      }
      return !this.reservedDates.includes(day.format('YYYY/MM/DD'))
    },
    isValidInput() {
      if (!!this.checkInDate && !!this.checkOutDate) {
        let checkOut = dayjs(this.checkOutDate)
        let period = dayjs(this.reservablePeriod)
        if (checkOut.isAfter(period)) {
          this.errorMsg = `現在のプランでは${period.format('M月D日')}までしか予約できません。`
          return false
        }
        if (!!this.currentTicketAmounts && this.leftTicketAmounts < 0) {
          this.errorMsg = `予約チケットが${this.leftTicketAmounts * -1}枚不足しています`
          return false
        }
        return true
      }
      return false
    },
    formatDate(date) {
      return dayjs(date).format('M/D')
    },
    async modalShown(isCheckInOnly) {
      this.isLoaded = false
      await this.fetchRoomDate()
      await this.fetchTicketAmount(this)
      if (isCheckInOnly) {
        this.checkOutDate = null
      }

      this.$refs.dateCalendarPicker.forceRerender()
    },
  },
}
</script>

<style lang="scss">
.loading-modal {
  padding: 8px;
}

.vhd__datepicker__month {
  height: 100% !important;
  margin-bottom: 0 !important;
}

.ticket-amounts {
  display: flex;
  flex-direction: column;
}

.staying-nights {
  min-height: 1.8rem;
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  padding: 1.5rem;
}

.btn-close {
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
}
</style>
