<template>
  <div>
    <ul v-if="reviews.length > 0" class="homedetail-review-list">
      <li v-for="review in summarizedReviews" :key="review.id" class="homedetail-review-li">
        <div class="homedetail-review-li-inner">
          <p class="homedetail-review-date">{{ review.created_at }} | {{ review.room_name }}</p>
          <!-- eslint-disable vue/no-v-html -->
          <p ref="reviewText" class="homedetail-review-txt" v-html="review.body"></p>
          <!--eslint-enable-->
          <button v-show="review.showMoreButton" class="homedetail-review-morebtn" @click="openModal(review.id)">
            すべて表示
          </button>
        </div>
        <div class="homedetail-review-user">
          <p class="homedetail-review-user-icon"><img :src="review.user.icon_url" /></p>
          <p class="homedetail-review-user-name">{{ review.user.name }}</p>
        </div>
      </li>
    </ul>
    <p v-else>レビューはまだありません</p>
    <button v-if="reviews.length > 0" class="homedetail-morebtn" @click="openModal">
      {{ totalCount }}件のレビューをすべて表示
    </button>
    <Teleport to="body">
      <div class="reviews-modal">
        <modal
          name="reviews-modal"
          height="auto"
          classes="modal-wrapper"
          scrollable
          style="display: flex; align-items: flex-start; justify-content: center; height: 100%"
        >
          <button type="button" class="modal-closebtn" @click.prevent="closeModal"></button>
          <div class="home-info-modal">
            <div class="homedetail-modal">
              <div class="homedetail-modal-content">
                <div class="homedetail-modal-main">
                  <div class="homedetail-modal-review-header">
                    <h2>レビュー</h2>
                    <p v-if="hasRating" class="homedetail-modal-review-header-score">{{ rating }}</p>
                    <div v-if="hasRating" class="homedetail-modal-review-header-star">
                      <div class="homedetail-modal-review-header-star-on" :style="`width:calc(${percentizedRating}%);`"></div>
                    </div>
                    <p class="homedetail-modal-review-header-amount">{{ totalCount }}件</p>
                  </div>
                  <div class="homedetail-modal-review-list">
                    <div
                      v-for="review in reviews"
                      :key="review.id"
                      :ref="`review-${review.id}`"
                      class="homedetail-modal-review-li"
                    >
                      <div class="homedetail-modal-review-li-inner">
                        <p class="homedetail-modal-review-date">{{ review.created_at }} | {{ review.room_name }}</p>
                        <!-- eslint-disable vue/no-v-html -->
                        <p v-html="review.body"></p>
                        <!--eslint-enable-->
                        <content-menu v-if="isSignedIn">
                          <report-button :report-id="review.id" report-type="Review" :report-api-url="reportApiUrl" />
                        </content-menu>
                      </div>
                      <div class="homedetail-modal-review-bottom">
                        <div class="homedetail-modal-review-user">
                          <p class="homedetail-modal-review-user-icon"><img :src="review.user.icon_url" /></p>
                          <p class="homedetail-modal-review-user-name">{{ review.user.name }}</p>
                        </div>
                        <div class="c-yamori-review-goodbtn">
                          <button :class="{ inverted: isBenefiting(review) }" @click.prevent="toggleBenefiting(review)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
                              <path
                                d="M4,13.79v-7a.978.978,0,0,0-1-.97H1a1,1,0,0,0-1,.97v6.97a.978.978,0,0,0,1,.97H3A.9.9,0,0,0,4,13.79ZM16,6.82a1.5,1.5,0,0,0-1.5-1.5H9.92a7.652,7.652,0,0,0,1.08-3A1.508,1.508,0,0,0,9.45.82C7.48.82,8.63,3.2,6.07,5.25l-.51.4A1.528,1.528,0,0,0,5,6.82H5v5a1.492,1.492,0,0,0,.6,1.2l1.07.8a4.986,4.986,0,0,0,3,1H11.5a1.5,1.5,0,0,0,1.5-1.5,1.859,1.859,0,0,0-.04-.33A1.5,1.5,0,0,0,14,11.57a1.477,1.477,0,0,0-.22-.78A1.5,1.5,0,0,0,15,9.32a1.474,1.474,0,0,0-.4-1.01A1.5,1.5,0,0,0,16,6.82Z"
                                transform="translate(0 -0.82)"
                                fill="#ac9890"
                              />
                            </svg>
                            役に立った
                            <span class="pl-1">{{ review.beneficial_count }}</span>
                          </button>
                        </div>
                      </div>
                      <div v-if="review.response_message" class="homedetail-modal-review-reply">
                        <p class="homedetail-modal-review-reply-ttl">家守からの返信</p>
                        <p class="homedetail-modal-review-reply-date">{{ review.responsed_at }}</p>
                        <p>{{ review.response_message }}</p>
                      </div>
                    </div>
                    <button v-if="hasNext" class="homedetail-modal-review-morebtn" @click.prevent="loadReviews">
                      もっと見る
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#cf761d"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        style="vertical-align: sub"
                      >
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </modal>
      </div>
    </Teleport>
  </div>
</template>

<script>
import axios from 'axios'
import Teleport from 'vue2-teleport'
import ReportButton from './ReportButton.vue'
import ContentMenu from './ContentMenu.vue'

export default {
  components: {
    Teleport,
    ReportButton,
    ContentMenu,
  },
  props: {
    reviewsApiUrl: {
      type: String,
      required: true,
    },
    reportApiUrl: {
      type: String,
      required: true,
    },
    beneficialApiUrl: {
      type: String,
      required: true,
    },
    isSignedIn: {
      type: Boolean,
      required: true,
    },
    rating: {
      type: String,
      default: '-.-',
    },
    percentizedRating: {
      type: Number,
      default: 0,
    },
    defaultBenefitedReviewIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      reviews: [],
      hasNext: false,
      totalCount: 0,
      currentPage: 1,
      isLoaded: false,
      benefitedReviewIds: [],
    }
  },
  computed: {
    summarizedReviews() {
      return this.reviews.slice(0, 6)
    },
    hasRating() {
      return this.rating !== ''
    },
  },
  async mounted() {
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }
    await this.fetchReviews()
    this.benefitedReviewIds = this.defaultBenefitedReviewIds
  },
  methods: {
    async fetchReviews() {
      await axios.get(this.reviewsApiUrl).then((response) => {
        this.reviews = response.data.reviews
        this.totalCount = response.data.total_count
        this.isLoaded = true
        this.hasNext = response.data.has_next
        this.$nextTick(this.checkOverflow)
      })
    },
    checkOverflow() {
      this.reviews.forEach((review, index) => {
        const textElement = this.$refs.reviewText[index]
        if (textElement.scrollHeight > textElement.clientHeight) {
          this.$set(this.reviews[index], 'showMoreButton', true)
        }
      })
    },
    async loadReviews() {
      this.isLoaded = false
      this.currentPage++
      const nextPage = this.currentPage
      await axios.get(this.reviewsApiUrl, { params: { page: nextPage } }).then((response) => {
        this.reviews.push(...response.data.reviews)
        this.isLoaded = true
        this.hasNext = response.data.has_next
      })
    },
    openModal(targetId) {
      this.$modal.show('reviews-modal')

      setTimeout(() => {
        const target = this.$refs[`review-${targetId}`]
        if (!target) return
        if (target instanceof Array) {
          target[0].scrollIntoView({ behavior: 'smooth', block: 'start' })
        } else {
          target.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      })
    },
    closeModal() {
      this.$modal.hide('reviews-modal')
    },
    isBenefiting(review) {
      return this.benefitedReviewIds.includes(review.id)
    },
    async toggleBenefiting(review) {
      const apiUrl = this.beneficialApiUrl.replace(':review-id:', review.id)
      if (this.isBenefiting(review)) {
        await axios.delete(apiUrl).then(() => {
          const index = this.benefitedReviewIds.indexOf(review.id)
          if (index !== -1) {
            this.benefitedReviewIds.splice(index, 1)
          }
          this.$set(
            this.reviews.find((r) => r.id === review.id),
            'beneficial_count',
            review.beneficial_count - 1
          )
        })
      } else {
        await axios.post(apiUrl).then(() => {
          this.benefitedReviewIds.push(review.id)
          this.$set(
            this.reviews.find((r) => r.id === review.id),
            'beneficial_count',
            review.beneficial_count + 1
          )
        })
      }
    },
  },
}
</script>

<style lang="scss">
.reviews-modal {
  .vm--container {
    z-index: 1030 !important;
  }

  & .vm--modal {
    top: initial !important;
    left: initial !important;
    width: initial !important;
    height: initial !important;
    margin-top: 20px !important;
    background-color: initial !important;
    border-radius: 0 !important;
    box-shadow: initial !important;

    @media (width <= 767px) {
      width: 100vw !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  & .vm--block-scroll header {
    z-index: initial !important;
  }

  & .vm--block-scroll .sticky {
    z-index: initial !important;
  }
}

.home-info-modal {
  position: relative;
  overflow-y: scroll;
  -ms-overflow-style: none;

  @media (width >= 768px) {
    &::-webkit-scrollbar {
      width: 10px;
      background: #fff;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e2e2e2;
      border-radius: 4px;
    }
  }

  @media (width >= 768px) {
    max-height: 90vh;
    border-radius: 10px;
  }
  @media (width <= 767px) {
    display: block;
    height: 100vh;
  }
}
</style>
