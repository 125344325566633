<template>
  <HotelDatePicker
    ref="hotelDatePicker"
    :class="pickerClass"
    format="YYYY/MM/DD"
    :always-visible="alwaysVisible"
    :start-date="calStartDate"
    :end-date="calEndDate"
    :last-date-available="lastDateAvailable"
    :starting-date-value="startingDateValue"
    :ending-date-value="endingDateValue"
    :disabled-dates="dynamicDisabledDates"
    :min-nights="minNights"
    :max-nights="maxNights"
    :half-day="false"
    :hovering-tooltip="false"
    :show-single-month="true"
    :grid-style="false"
    :close-date-picker-on-click-outside="!alwaysVisible"
    :i18n="i18n"
    @check-in-changed="checkInChanged"
    @check-out-changed="checkOutChanged"
  ></HotelDatePicker>
</template>

<script>
import HotelDatePicker from 'vue-hotel-datepicker'
import 'vue-hotel-datepicker/dist/vueHotelDatepicker.css'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import 'dayjs/locale/ja'
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')
dayjs.locale('ja')

export default {
  components: {
    HotelDatePicker,
  },
  props: {
    startDate: {
      type: String,
      default: null,
    },
    endDate: {
      type: String,
      required: true,
    },
    startDateValue: {
      type: String,
      default: null,
    },
    endDateValue: {
      type: String,
      default: null,
    },
    maxNights: {
      type: Number,
      default: null,
    },
    minNights: {
      type: Number,
      default: 1,
    },
    disabledDates: {
      type: Array,
      default: () => [],
    },
    holydays: {
      type: Array,
      default: () => [],
    },
    alwaysVisible: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'left',
    },
  },
  data: function () {
    return {
      i18n: {
        night: '日',
        nights: '日',
        week: '週',
        weeks: '週',
        'day-names': ['日', '月', '火', '水', '木', '金', '土'],
        'check-in': '到着日',
        'check-out': '出発日',
        'month-names': ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        tooltip: {
          halfDayCheckIn: 'Available CheckIn',
          halfDayCheckOut: 'Available CheckOut',
          saturdayToSaturday: 'Only Saturday to Saturday',
          sundayToSunday: 'Only Sunday to Sunday',
          minimumRequiredPeriod: '最低%{minNightInPeriod}%{night}',
        },
      },
      checkInDate: null,
      checkOutDate: null,
    }
  },
  computed: {
    pickerClass() {
      let classes = []
      classes.push('date-calendar-picker')
      classes.push('date-calendar-picker-pos-' + this.position)
      classes.push('date-calendar-picker-vis-' + this.alwaysVisible)
      return classes.join(' ')
    },
    calStartDate() {
      if (this.startDate !== null) {
        return new Date(this.startDate)
      } else {
        return new Date()
      }
    },
    calEndDate() {
      if (this.endDate !== null) {
        return dayjs(this.endDate).add(1, 'day').toDate()
      } else {
        return dayjs().add(1, 'day').add(3, 'month').toDate()
      }
    },
    lastDateAvailable() {
      const endDate = this.calEndDate
      return new Date(endDate.getFullYear(), endDate.getMonth() + 1)
    },
    startingDateValue() {
      if (this.startDateValue !== null) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.checkInDate = dayjs(this.startDateValue)
        return new Date(this.startDateValue)
      } else {
        return null
      }
    },
    endingDateValue() {
      if (this.endDateValue !== null) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.checkOutDate = dayjs(this.endDateValue)
        return new Date(this.endDateValue)
      } else {
        return null
      }
    },
    disabledDatesWithHolyday() {
      let newDisabledDates = this.disabledDates.concat() // clone array
      if (this.holydays.length !== 0) {
        const startDate = dayjs(this.calStartDate)
        const endDate = dayjs(this.calEndDate)
        let currentDate = startDate
        while (currentDate.isBefore(endDate)) {
          if (this.holydays.includes(parseInt(currentDate.format('d')))) {
            const currentDateStr = currentDate.format('YYYY/MM/DD')
            if (newDisabledDates.indexOf(currentDateStr) === -1) {
              newDisabledDates.push(currentDateStr)
            }
          }
          currentDate = currentDate.add(1, 'day')
        }
      }
      return newDisabledDates.sort()
    },
    dynamicDisabledDates() {
      let newDisabledDates = this.disabledDatesWithHolyday.concat() // clone array
      newDisabledDates.push(dayjs(this.calEndDate).format('YYYY/MM/DD'))

      if (this.checkInDate !== null) {
        if (this.checkOutDate !== null) {
          return newDisabledDates.filter((date) => date !== this.checkOutDate.format('YYYY/MM/DD'))
        } else {
          let newEnableDate = null
          let newDisableDate = null
          newDisabledDates.some((date) => {
            if (dayjs(date).isAfter(this.checkInDate)) {
              newEnableDate = date
              newDisableDate = dayjs(date).add(1, 'day').format('YYYY/MM/DD')
              return true
            }
          })
          if (newDisabledDates.indexOf(newDisableDate) === -1) {
            newDisabledDates.push(newDisableDate)
          }
          return newDisabledDates.filter((date) => date !== newEnableDate)
        }
      } else {
        return newDisabledDates
      }
    },
  },
  methods: {
    forceRerender() {
      this.$refs.hotelDatePicker.reRender()
    },
    clearSelection() {
      this.$refs.hotelDatePicker.clearSelection()
    },
    checkInChanged(date) {
      if (date !== null) {
        this.checkInDate = dayjs(date)
        this.$emit('check-in-changed', dayjs(date))
      } else {
        this.checkInDate = null
        this.$emit('check-in-changed', null)
      }
    },
    checkOutChanged(date) {
      if (date !== null) {
        this.checkOutDate = dayjs(date)
        this.$emit('check-out-changed', dayjs(date))
      } else {
        this.checkOutDate = null
        this.$emit('check-out-changed', null)
      }
    },
  },
}
</script>

<style lang="scss">
.date-calendar-picker {
  min-height: 48px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24' fill='none' stroke='%23AC9890' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='3' y='4' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Cline x1='16' y1='2' x2='16' y2='6'%3E%3C/line%3E%3Cline x1='8' y1='2' x2='8' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='10' x2='21' y2='10'%3E%3C/line%3E%3C/svg%3E");

  .vhd__datepicker__dummy-wrapper {
    padding: 0 1.2em;
    border: 1px solid #b5b5b5;
    border-radius: 6px;
  }

  .vhd__datepicker__input {
    height: 3em;
    padding: 0;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 16px;
    line-height: 2.75em;
    color: #67635e;
  }

  .vhd__datepicker__close-button i {
    font-size: 38px;
    font-weight: 100;
  }

  .vhd__datepicker__dummy-wrapper--no-border {
    border: none;
    border-bottom: 1px solid #b5b5b5;
    border-radius: 0;
  }

  .vhd__datepicker__week-row {
    display: block;
    box-shadow: none;
  }

  .vhd__datepicker__months.vhd__datepicker__months--full {
    @media (width <= 767.98px) {
      padding-top: 50px;
    }
  }

  .vhd__datepicker__month-button {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24' fill='none' stroke='%2367635E' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 12h13M12 5l7 7-7 7'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px;
    border: none;
  }

  .vhd__datepicker__month-name {
    padding-top: 0.4em;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 20px;
    font-weight: 400;
    @media (width <= 767.98px) {
      margin-top: -2.85em;
    }
  }

  .vhd__datepicker__week-name {
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 1.4em;
    font-weight: 400;
  }

  .day {
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 1.4em;
    font-weight: 400;
  }

  .vhd__datepicker__month-day--today {
    .vhd__datepicker__month-day-wrapper {
      padding-top: calc(100% - 1px);
      border: none;
    }
  }

  .vhd__datepicker__month-day--disabled {
    background-color: #fff;
  }

  .vhd__datepicker__month-day--valid:hover,
  .vhd__datepicker__month-day--allowed-checkout:hover,
  .vhd__datepicker__month-day--first-day-selected,
  .vhd__datepicker__month-day--last-day-selected {
    background-color: #ecc8a5;

    .vhd__datepicker__month-day-wrapper {
      background-color: #cf761d;
      border-radius: 50%;
    }
  }

  .vhd__datepicker__month-day--valid:hover {
    background-color: #cf761d;
    border-radius: 50%;
  }

  .vhd__datepicker__month-day--valid.vhd__datepicker__month-day--disabled {
    .vhd__datepicker__month-day-wrapper {
      font-weight: 700;
      color: #fff;
      background-color: #cf761d;
      border-radius: 50%;
    }
  }

  .vhd__datepicker__month-day--first-day-selected {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }

  .vhd__datepicker__month-day--allowed-checkout:hover,
  .vhd__datepicker__month-day--last-day-selected {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  .vhd__datepicker__month-day--selected {
    background-color: #ecc8a5;
  }

  .vhd__datepicker__month-day--selected.vhd__currentDay {
    background-color: #ecc8a5;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;

    .vhd__datepicker__month-day-wrapper {
      background-color: #cf761d;
      border-radius: 50%;
    }
  }

  .vhd__datepicker__month-day--selected.vhd__datepicker__month-day--disabled {
    background-color: #cf761d;
  }
}

.date-calendar-picker-pos-right {
  .vhd__datepicker {
    right: 0;
  }
}

.date-calendar-picker-vis-false {
  .vhd__datepicker__header-mobile {
    padding-top: 0.8em;
  }
}
</style>
