<template>
  <div>
    <div class="search-form">
      <div class="search-form_inner flex">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#CF761D"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <circle cx="11" cy="11" r="8"></circle>
          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        </svg>
        <button type="button" class="search-form_field" data-bs-toggle="modal" data-bs-target="#homeSearchModal">
          <p>{{ searchFieldLabel }}</p>
        </button>
      </div>
    </div>
    <HomeSearchModal
      :icon-original-img-src="iconOriginalImgSrc"
      :is-legacy-plan="isLegacyPlan"
      :is-contracted="isContracted"
      :cal-end-date="calEndDate"
      :default-form-data="defaultFormData"
      :tag-labels="tagLabels"
      @update-search-field-label="searchFieldLabel = $event"
    ></HomeSearchModal>
  </div>
</template>

<script>
import HomeSearchModal from './HomeSearchModal'

export default {
  name: 'HomeSearch',
  components: { HomeSearchModal },
  props: {
    defaultFormData: {
      type: Object,
      required: true,
    },
    calEndDate: {
      type: String,
      required: true,
    },
    isContracted: {
      type: Boolean,
      required: true,
    },
    isLegacyPlan: {
      type: Boolean,
      required: true,
    },
    iconOriginalImgSrc: {
      type: String,
      required: true,
    },
    tagLabels: {
      type: String,
      default: null,
    },
  },
  data: function () {
    return {
      searchFieldLabel: 'エリア、日付、人数、こだわりで検索',
    }
  },
}
</script>
