<template>
  <div>
    <div
      id="homeSearchModal"
      class="modal home-search-modal fade"
      tabindex="-1"
      aria-labelledby="homeSearchModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered modal-fullscreen-md-down">
        <div v-if="currentPanel === 'detail'" class="modal-content">
          <div class="modal-title d-md-none">
            <p class="l-title">家の検索</p>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="searchmenu-head flex col-sm-12">
                <div class="flex"></div>
                <div class="searchmenu-head_clear" @click="clearForm()">条件をクリア</div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="mb-4">
                  <label>エリア</label>
                  <div class="search-list flex" @click="changePanel('area')">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#AC9890"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <circle cx="12" cy="10" r="3" />
                      <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                    </svg>
                    {{ areaName() }}
                  </div>
                </div>

                <div class="mb-4">
                  <label>日程</label>
                  <DateCalendarPicker
                    ref="dateCalendarPicker"
                    :end-date="calEndDate"
                    :start-date-value="startDateValue()"
                    :end-date-value="endDateValue()"
                    @check-in-changed="checkInChanged"
                    @check-out-changed="checkOutChanged"
                  ></DateCalendarPicker>
                </div>

                <div class="mb-5">
                  <div class="d-flex search-people-rooms">
                    <div class="d-flex search-peoplebox">
                      <div class="w-100">
                        <label>大人</label>
                        <select v-model="formData.adult" class="form-select search-people-adult">
                          <option v-for="count in adultSelect" :key="`adult-${count}`" :value="count">
                            {{ count }}
                          </option>
                        </select>
                      </div>
                      <div class="w-100">
                        <label>子ども</label>
                        <select v-model="formData.child" class="form-select search-people-child">
                          <option v-for="count in rangeNumbers(0, 6)" :key="`child-${count}`" :value="count">
                            {{ count }}
                          </option>
                        </select>
                      </div>
                      <div class="w-100">
                        <label>幼児</label>
                        <select v-model="formData.preschooler" class="form-select search-people-preschooler">
                          <option v-for="count in rangeNumbers(0, 6)" :key="`preschooler-${count}`" :value="count">
                            {{ count }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div v-if="!isLegacyPlan" class="search-roomsbox">
                      <label>部屋数</label>
                      <select v-model="formData.roomCount" class="form-select">
                        <option v-for="count in rangeNumbers(1, 2)" :key="`room-count-${count}`" :value="count">
                          {{ count }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="mt-2">
                    <svg
                      style="margin-top: 3px"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 28 28"
                      fill="none"
                      stroke="#CF761D"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                      <line x1="12" y1="17" x2="12.01" y2="17"></line>
                    </svg>
                    <a :href="capacityHelpURL" class="btn btn-text2 text-sub" target="_blank" rel="noopener">
                      個室の定員について
                    </a>
                  </div>
                </div>

                <div class="mb-4">
                  <label class="text-bold">家タイプ</label>
                  <a href="https://sites.google.com/address.love/popup/guide/housetype" target="_blank" rel="noopener">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 28 28"
                      fill="none"
                      stroke="#CF761D"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                      <line x1="12" y1="17" x2="12.01" y2="17"></line>
                    </svg>
                  </a>
                  <div class="d-flex flex-wrap">
                    <div v-for="kind in homeKinds" :key="'kind-' + kind.value" class="checkbox mr-2">
                      <input :id="'kind_' + kind.value" v-model="formData.homeKinds" type="checkbox" :value="kind.value" />
                      <label :for="'kind_' + kind.value" class="mr-2">{{ kind.name }}</label>
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <label class="text-bold">部屋タイプ</label>
                  <div class="d-flex flex-wrap">
                    <div class="checkbox mr-2">
                      <input
                        id="room_kind_private"
                        v-model="formData.roomKinds"
                        type="checkbox"
                        name="room_kinds"
                        value="private"
                      />
                      <label for="room_kind_private" class="mr-2">個室</label>
                    </div>
                    <div class="checkbox mr-2">
                      <input
                        id="room_kind_dormitory"
                        v-model="formData.roomKinds"
                        type="checkbox"
                        name="room_kinds"
                        value="dormitory"
                      />
                      <label for="room_kind_dormitory" class="mr-2">ドミトリー</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 searchmenu-right">
                <div class="mb-4">
                  <label class="text-bold">こだわり検索</label>
                  <div class="checkbox">
                    <p class="text-sub mt-1 mb-1">家/設備</p>
                    <!--<input type="checkbox" id="coworking" v-model="formData.coworking">-->
                    <!--<label for="coworking">コワーキングがある</label>-->
                    <div>
                      <input id="original" v-model="formData.original" type="checkbox" />
                      <label for="original" style="display: inline-block">
                        <img :src="iconOriginalImgSrc" class="searchmenu-original" />
                        ADDressオリジナル
                      </label>
                      <a href="https://sites.google.com/address.love/popup/guide/original" target="_blank" rel="noopener">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 28 28"
                          fill="none"
                          stroke="#CF761D"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                          <line x1="12" y1="17" x2="12.01" y2="17"></line>
                        </svg>
                      </a>
                    </div>
                    <div>
                      <input id="kitchen" v-model="formData.kitchen" type="checkbox" />
                      <label for="kitchen">キッチンあり</label>
                    </div>
                    <div class="d-flex">
                      <div>
                        <input id="parking" v-model="formData.parking" type="checkbox" @change="parkingChecked($event)" />
                        <label for="parking">駐車場あり</label>
                      </div>
                      <p>（</p>
                      <div class="mr-2">
                        <input id="parking_free" v-model="formData.parkingFree" type="checkbox" @change="parkingDetailChecked" />
                        <label for="parking_free" class="ml-2">無料</label>
                      </div>
                      <div>
                        <input
                          id="parking_prebooking"
                          v-model="formData.parkingPrebooking"
                          type="checkbox"
                          @change="parkingDetailChecked"
                        />
                        <label for="parking_prebooking" class="ml-2 mr-1">事前予約可</label>
                      </div>
                      <p>）</p>
                    </div>
                    <div>
                      <input id="desk_chair" v-model="formData.deskChair" type="checkbox" />
                      <label for="desk_chair">部屋にデスクチェアあり</label>
                    </div>

                    <p class="text-sub mt-3 mb-1">交流</p>
                    <div>
                      <input v-if="isContracted" id="yamori_interaction" v-model="formData.yamoriInteraction" type="checkbox" />
                      <label v-if="isContracted" for="yamori_interaction" style="display: inline-block">家守に会える</label>
                      <a
                        v-if="isContracted"
                        href="https://sites.google.com/address.love/popup/guide/yamori"
                        target="_blank"
                        rel="noopener"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 28 28"
                          fill="none"
                          stroke="#CF761D"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                          <line x1="12" y1="17" x2="12.01" y2="17"></line>
                        </svg>
                      </a>
                    </div>

                    <div>
                      <input id="member_only" v-model="formData.memberOnly" type="checkbox" />
                      <label for="member_only" style="display: inline-block">ADDress会員だけが利用できる</label>
                      <a href="https://sites.google.com/address.love/popup/guide/exclusive" target="_blank" rel="noopener">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 28 28"
                          fill="none"
                          stroke="#CF761D"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                          <line x1="12" y1="17" x2="12.01" y2="17"></line>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <label class="text-bold">予約オプション</label>
                  <div v-if="isLegacyPlan" class="d-flex flex-wrap">
                    <div class="pr-1">予約制限</div>
                    <a
                      href="https://sites.google.com/address.love/popup/guide/housegroup"
                      target="_blank"
                      rel="noopener"
                      class="mr-3"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 28 28"
                        fill="none"
                        stroke="#CF761D"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                      </svg>
                    </a>
                    <div v-for="kind in groupKinds" :key="'kind-' + kind.value" class="checkbox mr-2">
                      <input :id="'kind_' + kind.value" v-model="formData.groupKinds" type="checkbox" :value="kind.value" />
                      <label :for="'kind_' + kind.value" class="mr-2">{{ kind.name }}</label>
                    </div>
                  </div>
                  <div class="checkbox">
                    <input id="is_auto_approved" v-model="formData.isAutoApproved" type="checkbox" />
                    <label for="is_auto_approved">
                      家守の予約承認が不要な家
                      <a :href="autoApproveHelpURL" target="_blank" rel="noopener" class="mr-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 28 28"
                          fill="none"
                          stroke="#CF761D"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                          <line x1="12" y1="17" x2="12.01" y2="17"></line>
                        </svg>
                      </a>
                    </label>
                  </div>
                </div>

                <div class="mb-4">
                  <label class="text-bold">割引き</label>
                  <span class="label-new ml-1 mb-1">NEW</span>
                  <div class="d-flex checkbox">
                    <div>
                      <input id="discount" v-model="formData.discount" type="checkbox" @change="discountChecked" />
                      <label for="discount">
                        連泊割
                        <a :href="discountHelpURL" target="_blank" rel="noopener">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 28 28"
                            fill="none"
                            stroke="#CF761D"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                            <line x1="12" y1="17" x2="12.01" y2="17"></line>
                          </svg>
                        </a>
                      </label>
                    </div>
                    <p>（</p>
                    <div class="mr-2">
                      <input
                        id="discount_3_nights"
                        v-model="formData.discount3Nights"
                        type="checkbox"
                        @change="discountDetailChecked"
                      />
                      <label for="discount_3_nights" class="ml-2">3泊割</label>
                    </div>
                    <div>
                      <input
                        id="discount_7_nights"
                        v-model="formData.discount7Nights"
                        type="checkbox"
                        @change="discountDetailChecked"
                      />
                      <label for="discount_7_nights" class="ml-2 mr-1">7泊割</label>
                    </div>
                    <p>）</p>
                  </div>
                </div>

                <div v-if="isContracted" class="mb-4">
                  <label class="text-bold">表示条件</label>
                  <div class="checkbox">
                    <input id="favorite" v-model="formData.favorite" type="checkbox" />
                    <label for="favorite">お気に入りに登録した家のみ表示</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer py-4">
            <div class="flex">
              <button type="button" class="btn btn-gray size-footer" data-bs-dismiss="modal">閉じる</button>
              <button type="button" class="btn btn-primary size-footer" @click="search">検索</button>
            </div>
          </div>
        </div>

        <div v-if="currentPanel === 'area'" class="modal-content">
          <div class="modal-title d-md-none">
            <p class="l-title">エリアの選択</p>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="searchmenu-head flex col-sm-12">
                <div class="searchmenu-head_back flex" @click="changePanel('detail')">戻る</div>
                <div class="searchmenu-head_clear" @click="clearCheckedAreaPrefs">クリア</div>
              </div>
              <div v-for="area in areas" :key="'area-' + area.id">
                <div class="mx-md-3">
                  <div class="checkbox mb-3">
                    <input
                      :id="'area_' + area.id"
                      type="checkbox"
                      :checked="checkedAreas.includes(area.id)"
                      @change="areaChanged($event, area)"
                    />
                    <label :for="'area_' + area.id">{{ area.name + 'エリア' }}</label>
                  </div>
                  <div class="prefectures-list mb-4">
                    <div v-for="pref in area.prefectures" :key="'pref-' + pref.id" class="checkbox mx-2 mb-3">
                      <input
                        :id="'pref_' + pref.id"
                        type="checkbox"
                        class="checkbox mx-2 mb-3"
                        :checked="checkedPrefs.includes(pref.id)"
                        @change="prefChanged($event, pref)"
                      />
                      <label :for="'pref_' + pref.id">{{ pref.name }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer py-4 row">
            <div class="d-flex justify-content-end justify-content-md-center">
              <button type="button" class="btn btn-primary size-footer" @click="saveCheckedAreaPrefs">選択</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateCalendarPicker from 'vuejs/shared/components/DateCalendarPicker'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import 'dayjs/locale/ja'
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')
dayjs.locale('ja')

const AREAS = [
  {
    id: 1,
    name: '北海道',
    prefectures: [{ id: 1, name: '北海道' }],
  },
  {
    id: 2,
    name: '東北',
    prefectures: [
      { id: 2, name: '青森県' },
      { id: 3, name: '岩手県' },
      { id: 4, name: '宮城県' },
      { id: 5, name: '秋田県' },
      { id: 6, name: '山形県' },
      { id: 7, name: '福島県' },
    ],
  },
  {
    id: 3,
    name: '北関東',
    prefectures: [
      { id: 8, name: '茨城県' },
      { id: 9, name: '栃木県' },
      { id: 10, name: '群馬県' },
    ],
  },
  {
    id: 4,
    name: '首都圏',
    prefectures: [
      { id: 11, name: '埼玉県' },
      { id: 12, name: '千葉県' },
      { id: 13, name: '東京都' },
      { id: 14, name: '神奈川県' },
    ],
  },
  {
    id: 5,
    name: '甲信越',
    prefectures: [
      { id: 15, name: '新潟県' },
      { id: 16, name: '山梨県' },
      { id: 17, name: '長野県' },
    ],
  },
  {
    id: 6,
    name: '北陸',
    prefectures: [
      { id: 18, name: '富山県' },
      { id: 19, name: '石川県' },
      { id: 20, name: '福井県' },
    ],
  },
  {
    id: 7,
    name: '東海',
    prefectures: [
      { id: 21, name: '岐阜県' },
      { id: 22, name: '静岡県' },
      { id: 23, name: '愛知県' },
      { id: 24, name: '三重県' },
    ],
  },
  {
    id: 8,
    name: '関西',
    prefectures: [
      { id: 25, name: '滋賀県' },
      { id: 26, name: '京都府' },
      { id: 27, name: '大阪府' },
      { id: 28, name: '兵庫県' },
      { id: 29, name: '奈良県' },
      { id: 30, name: '和歌山県' },
    ],
  },
  {
    id: 9,
    name: '中国',
    prefectures: [
      { id: 31, name: '鳥取県' },
      { id: 32, name: '島根県' },
      { id: 33, name: '岡山県' },
      { id: 34, name: '広島県' },
      { id: 35, name: '山口県' },
    ],
  },
  {
    id: 10,
    name: '四国',
    prefectures: [
      { id: 36, name: '徳島県' },
      { id: 37, name: '香川県' },
      { id: 38, name: '愛媛県' },
      { id: 39, name: '高知県' },
    ],
  },
  {
    id: 11,
    name: '九州',
    prefectures: [
      { id: 40, name: '福岡県' },
      { id: 41, name: '佐賀県' },
      { id: 42, name: '長崎県' },
      { id: 43, name: '熊本県' },
      { id: 44, name: '大分県' },
      { id: 45, name: '宮崎県' },
      { id: 46, name: '鹿児島県' },
    ],
  },
  {
    id: 12,
    name: '沖縄',
    prefectures: [{ id: 47, name: '沖縄県' }],
  },
  {
    id: 13,
    name: '海外',
    prefectures: [{ id: 48, name: '海外' }],
  },
]

const HOME_KINDS = [
  {
    name: '戸建て',
    value: 'detached_house',
  },
  {
    name: 'ホテル/旅館',
    value: 'hotel',
  },
  {
    name: 'ゲストハウス',
    value: 'guest_house',
  },
  {
    name: 'シェアハウス',
    value: 'share_house',
  },
  {
    name: 'キャンプサイト',
    value: 'camp_site',
  },
]

const GROUP_KINDS = [
  {
    name: 'なし',
    value: 'unlimited',
  },
  {
    name: 'あり',
    value: 'limited',
  },
]

const removeItem = (array, item) => {
  return array.filter((arrItem) => {
    return arrItem !== item
  })
}

const allPrefsChecked = (areaObj, checkedPrefIds) => {
  let hasAllPrefs = true
  areaObj.prefectures.forEach((pref) => {
    if (!checkedPrefIds.includes(pref.id)) {
      hasAllPrefs = false
    }
  })
  return hasAllPrefs
}

const findArea = (prefId) => {
  let result = null
  AREAS.forEach((area) => {
    let prefIndex = area.prefectures.findIndex((pref) => {
      return pref.id === prefId
    })
    if (prefIndex >= 0) {
      result = area.id
    }
  })
  return result
}

const findPrefs = (areaId) => {
  const area = AREAS.find((area) => {
    return areaId === area.id
  })
  return area.prefectures
}

export default {
  name: 'HomeSearchModal',
  components: { DateCalendarPicker },
  props: {
    defaultFormData: {
      type: Object,
      required: true,
    },
    calEndDate: {
      type: String,
      required: true,
    },
    isContracted: {
      type: Boolean,
      required: true,
    },
    isLegacyPlan: {
      type: Boolean,
      required: true,
    },
    iconOriginalImgSrc: {
      type: String,
      required: true,
    },
    tagLabels: {
      type: String,
      default: null,
    },
  },
  data: function () {
    return {
      currentPanel: 'detail',
      areas: AREAS,
      homeKinds: HOME_KINDS,
      groupKinds: GROUP_KINDS,
      formData: {
        areas: [],
        prefs: [],
        homeKinds: [],
        roomKinds: [],
        groupKinds: [],
        isAutoApproved: false,
        original: false,
        yamoriInteraction: false,
        memberOnly: false,
        checkInDate: null,
        checkOutDate: null,
        adult: 1,
        child: 0,
        preschooler: 0,
        roomCount: 1,
        parking: false,
        parkingFree: false,
        parkingPrebooking: false,
        coworking: false,
        deskChair: false,
        kitchen: false,
        discount: false,
        discount3Nights: false,
        discount7Nights: false,
        favorite: false,
      },
      searchFieldLabel: 'エリア、日付、人数、こだわりで検索',
      checkedAreas: [],
      checkedPrefs: [],
    }
  },
  computed: {
    autoApproveHelpURL() {
      return this.isContracted
        ? 'https://address-membersupport.zendesk.com/hc/ja/articles/18881530606361'
        : 'https://address.zendesk.com/hc/ja/articles/19012713582873'
    },
    capacityHelpURL() {
      return this.isContracted
        ? 'https://address-membersupport.zendesk.com/hc/ja/articles/20051109977369'
        : 'https://address.zendesk.com/hc/ja/articles/23734687136153'
    },
    discountHelpURL() {
      return this.isContracted
        ? 'https://address-membersupport.zendesk.com/hc/ja/articles/24627105066393'
        : 'https://address.zendesk.com/hc/ja/articles/24641576023193'
    },
    adultSelect() {
      return this.formData.roomCount > 1 ? this.rangeNumbers(2, 6) : this.rangeNumbers(1, 6)
    },
  },
  watch: {
    formData: {
      handler(newData) {
        if (newData.roomCount > 1) {
          this.formData.adult = Math.max(2, this.formData.adult)
        }
      },
      deep: true,
    },
  },
  created: function () {
    this.formData.areas = this.defaultFormData.areas ? this.defaultFormData.areas.map((area) => parseInt(area)) : []
    this.formData.prefs = this.defaultFormData.prefectures ? this.defaultFormData.prefectures.map((pref) => parseInt(pref)) : []
    this.formData.tags = this.defaultFormData.tags ? this.defaultFormData.tags.map((tag) => parseInt(tag)) : []
    this.formData.homeKinds =
      !!this.defaultFormData.kinds && this.defaultFormData.kinds.length > 0 ? this.defaultFormData.kinds : []
    this.formData.checkInDate = this.defaultFormData.check_in_date ? dayjs(this.defaultFormData.check_in_date) : null
    this.formData.checkOutDate = this.defaultFormData.check_out_date ? dayjs(this.defaultFormData.check_out_date) : null
    this.formData.adult = this.defaultFormData.adult ? this.defaultFormData.adult : 1
    this.formData.child = this.defaultFormData.child ? this.defaultFormData.child : 0
    this.formData.preschooler = this.defaultFormData.preschooler ? this.defaultFormData.preschooler : 0
    this.formData.roomCount = this.defaultFormData.room_count ? this.defaultFormData.room_count : 0
    this.formData.roomKinds =
      !!this.defaultFormData.room_kinds && this.defaultFormData.room_kinds.length > 0 ? this.defaultFormData.room_kinds : []
    this.formData.groupKinds =
      !!this.defaultFormData.group_kinds && this.defaultFormData.group_kinds.length > 0 ? this.defaultFormData.group_kinds : []
    this.formData.isAutoApproved = !!this.defaultFormData.is_auto_approved
    // this.formData.coworking = !!this.defaultFormData.has_coworking
    this.formData.original = !!this.defaultFormData.is_original
    this.formData.yamoriInteraction = !!this.defaultFormData.yamori_interaction
    this.formData.memberOnly = !!this.defaultFormData.is_member_only
    this.formData.parking = !!this.defaultFormData.parking
    this.formData.parkingFree = !!this.defaultFormData.parking_free
    this.formData.parkingPrebooking = !!this.defaultFormData.parking_prebooking
    this.formData.coworking = !!this.defaultFormData.has_coworking
    this.formData.deskChair = !!this.defaultFormData.has_desk_chair
    this.formData.kitchen = !!this.defaultFormData.has_kitchen
    this.formData.discount = !!this.defaultFormData.discount
    this.formData.discount3Nights = !!this.defaultFormData.discount_3_nights
    this.formData.discount7Nights = !!this.defaultFormData.discount_7_nights
    this.formData.favorite = !!this.defaultFormData.only_favorite
    this.updateSearchFieldLabel()
    this.updateCheckedAreaPrefs()
  },
  mounted: function () {
    if (!this.formData.checkInDate || !this.formData.checkOutDate) {
      this.$refs.dateCalendarPicker.clearSelection()
    }
  },
  methods: {
    changePanel(name) {
      this.currentPanel = name
    },
    rangeNumbers(min, max) {
      return Array(max - min + 1)
        .fill()
        .map((x, i) => i + min)
    },
    areaChanged(event, area) {
      if (event.target.checked) {
        if (!this.checkedAreas.includes(area.id)) {
          this.checkedAreas.push(area.id)
        }
        area.prefectures.forEach((pref) => {
          if (!this.checkedPrefs.includes(pref.id)) {
            this.checkedPrefs.push(pref.id)
          }
        })
      } else {
        this.checkedAreas = removeItem(this.checkedAreas, area.id)
        area.prefectures.forEach((pref) => {
          this.checkedPrefs = removeItem(this.checkedPrefs, pref.id)
        })
      }
    },
    prefChanged(event, pref) {
      if (event.target.checked) {
        if (!this.checkedPrefs.includes(pref.id)) {
          this.checkedPrefs.push(pref.id)
        }
        this.areas.forEach((area) => {
          if (allPrefsChecked(area, this.checkedPrefs) && !this.checkedAreas.includes(area.id)) {
            this.checkedAreas.push(area.id)
          }
        })
      } else {
        this.checkedPrefs = removeItem(this.checkedPrefs, pref.id)
        this.areas.forEach((area) => {
          if (!allPrefsChecked(area, this.checkedPrefs)) {
            this.checkedAreas = removeItem(this.checkedAreas, area.id)
          }
        })
      }
    },
    parkingChecked(event) {
      if (!event.target.checked) {
        this.formData.parkingFree = false
        this.formData.parkingPrebooking = false
      }
    },
    parkingDetailChecked() {
      if (this.formData.parkingFree || this.formData.parkingPrebooking) {
        this.formData.parking = true
      }
    },
    discountChecked(event) {
      if (!event.target.checked) {
        this.formData.discount3Nights = false
        this.formData.discount7Nights = false
      }
    },
    discountDetailChecked() {
      if (this.formData.discount3Nights || this.formData.discount7Nights) {
        this.formData.discount = true
      }
    },
    clearCheckedAreaPrefs() {
      this.checkedAreas = []
      this.checkedPrefs = []
    },
    saveCheckedAreaPrefs() {
      this.formData = Object.assign(this.formData, { areas: this.checkedAreas.sort() })
      let prefs = this.checkedPrefs.slice()
      this.areas.forEach((area) => {
        if (allPrefsChecked(area, this.checkedPrefs)) {
          area.prefectures.forEach((pref) => {
            prefs = removeItem(prefs, pref.id)
          })
        }
      })
      this.formData = Object.assign(this.formData, { prefs: prefs.sort() })
      this.changePanel('detail')
    },
    areaName() {
      if (this.formData.prefs.length < 1 && this.formData.areas.length < 1) {
        return '全国'
      }
      let names = []
      if (this.formData.areas.length > 0) {
        this.formData.areas.forEach((savedArea) => {
          this.areas.forEach((area) => {
            if (savedArea === area.id) {
              names.push(area.name)
            }
          })
        })
      }
      if (this.formData.prefs.length > 0) {
        this.formData.prefs.forEach((savedPref) => {
          this.areas.forEach((area) => {
            area.prefectures.forEach((pref) => {
              if (savedPref === pref.id) {
                names.push(pref.name)
              }
            })
          })
        })
      }
      return names.join('、')
    },
    startDateValue() {
      if (this.formData.checkInDate !== null) {
        return this.formData.checkInDate.format('YYYY/MM/DD')
      }
      return null
    },
    endDateValue() {
      if (this.formData.checkOutDate !== null) {
        return this.formData.checkOutDate.format('YYYY/MM/DD')
      }
      return null
    },
    checkInChanged(date) {
      this.formData.checkInDate = date
    },
    checkOutChanged(date) {
      this.formData.checkOutDate = date
    },
    clearForm() {
      this.formData.areas = []
      this.formData.prefs = []
      this.checkedAreas = []
      this.checkedPrefs = []
      this.formData.tags = []
      this.formData.homeKinds = []
      this.formData.roomKinds = []
      this.formData.groupKinds = []
      this.formData.isAutoApproved = false
      this.formData.checkInDate = null
      this.formData.checkOutDate = null
      this.$refs.dateCalendarPicker.clearSelection()
      this.formData.adult = 1
      this.formData.child = 0
      this.formData.preschooler = 0
      this.formData.roomCount = 1
      this.formData.original = false
      this.formData.yamoriInteraction = false
      this.formData.memberOnly = false
      this.formData.parking = false
      this.formData.parkingFree = false
      this.formData.parkingPrebooking = false
      this.formData.coworking = false
      this.formData.deskChair = false
      this.formData.kitchen = false
      this.formData.discount = false
      this.formData.discount3Nights = false
      this.formData.discount7Nights = false
      this.formData.favorite = false
    },
    search() {
      const url = new URL(window.location)
      let pathName = '/homes'
      let searchParams = {}

      // エリア
      if (this.formData.areas.length === 1 && this.formData.prefs.length === 1) {
        const prefId = this.formData.prefs[0]
        const areaId = findArea(prefId)
        if (!!areaId && areaId === this.formData.areas[0]) {
          pathName += `/areas/${this.formData.areas[0]}/prefs/${this.formData.prefs[0]}`
        }
      } else if (this.formData.areas.length === 1 && this.formData.prefs.length === 0) {
        pathName += `/areas/${this.formData.areas[0]}`
      } else if (this.formData.prefs.length === 1 && this.formData.areas.length === 0) {
        // find area
        const prefId = this.formData.prefs[0]
        const areaId = findArea(prefId)
        if (areaId) {
          pathName += `/areas/${areaId}/prefs/${prefId}`
        }
      }
      if (pathName === '/homes') {
        pathName += '/search'
        if (this.formData.areas.length > 0) {
          searchParams.areas = this.formData.areas
        }
        if (this.formData.prefs.length > 0) {
          searchParams.prefs = this.formData.prefs
        }
      }

      // タグ
      if (!!this.formData.tags && this.formData.tags.length > 0) {
        searchParams.tags = this.formData.tags
      }

      // 日程
      if (!!this.formData.checkInDate && !!this.formData.checkOutDate) {
        searchParams.check_in_date = this.formData.checkInDate.format('YYYY-MM-DD')
        searchParams.check_out_date = this.formData.checkOutDate.format('YYYY-MM-DD')
      }

      // 家タイプ
      searchParams.kinds = !!this.formData.homeKinds && this.formData.homeKinds.length > 0 ? this.formData.homeKinds : null

      // 部屋タイプ
      searchParams.room_kinds = !!this.formData.roomKinds && this.formData.roomKinds.length > 0 ? this.formData.roomKinds : null

      // 予約オプション（旧「家グループ」）
      searchParams.group_kinds =
        !!this.formData.groupKinds && this.formData.groupKinds.length > 0 ? this.formData.groupKinds : null

      // Others
      searchParams.adult = this.formData.adult !== 1 ? this.formData.adult : null
      searchParams.child = this.formData.child
      searchParams.preschooler = this.formData.preschooler
      searchParams.room_count = this.formData.roomCount
      searchParams.original = this.formData.original
      searchParams.is_auto_approved = this.formData.isAutoApproved
      searchParams.yamori_interaction = this.formData.yamoriInteraction
      searchParams.member_only = this.formData.memberOnly
      searchParams.parking = this.formData.parking
      searchParams.parking_free = this.formData.parkingFree
      searchParams.parking_prebooking = this.formData.parkingPrebooking
      searchParams.coworking = this.formData.coworking
      searchParams.desk_chair = this.formData.deskChair
      searchParams.kitchen = this.formData.kitchen
      searchParams.favorite = this.formData.favorite
      searchParams.theme_list = this.defaultFormData.theme_list_id
      searchParams.discount = this.formData.discount
      searchParams.discount_3_nights = this.formData.discount3Nights
      searchParams.discount_7_nights = this.formData.discount7Nights

      url.pathname = pathName
      url.search = ''
      for (const [key, val] of Object.entries(searchParams)) {
        if (val) {
          url.searchParams.append(`q[${key}]`, val)
        }
      }
      window.location.href = url.href
    },
    updateSearchFieldLabel() {
      let labels = []

      // タグ
      if (this.tagLabels) {
        labels.push(this.tagLabels)
      }

      // エリア
      labels.push(this.areaName())

      // 日程
      if (!!this.formData.checkInDate && !!this.formData.checkOutDate) {
        labels.push(`${this.formData.checkInDate.format('M月D日')}〜${this.formData.checkOutDate.format('M月D日')}`)
      }

      // 人数
      let users = []
      if (!!this.formData.adult && this.formData.adult > 0) {
        users.push('大人' + this.formData.adult + '名')
      }
      if (!!this.formData.child && this.formData.child > 0) {
        users.push('子ども' + this.formData.child + '名')
      }
      if (!!this.formData.preschooler && this.formData.preschooler > 0) {
        users.push('幼児' + this.formData.preschooler + '名')
      }
      labels.push(users.join('/'))

      if (!!this.formData.roomCount && this.formData.roomCount > 1) {
        labels.push(`${this.formData.roomCount}部屋`)
      }

      // 部屋タイプ
      if (!!this.formData.roomKinds && this.formData.roomKinds.length > 0) {
        let roomKind = []
        if (this.formData.roomKinds.includes('private')) {
          roomKind.push('個室')
        }
        if (this.formData.roomKinds.includes('dormitory')) {
          roomKind.push('ドミトリー')
        }
        labels.push(roomKind.join('・'))
      }

      // 家タイプ
      if (!!this.formData.homeKinds && this.formData.homeKinds.length > 0) {
        let kinds = []
        if (this.formData.homeKinds.includes('detached_house')) {
          kinds.push('戸建て')
        }
        if (this.formData.homeKinds.includes('hotel')) {
          kinds.push('ホテル/旅館')
        }
        if (this.formData.homeKinds.includes('guest_house')) {
          kinds.push('ゲストハウス')
        }
        if (this.formData.homeKinds.includes('share_house')) {
          kinds.push('シェアハウス')
        }
        if (this.formData.homeKinds.includes('camp_site')) {
          kinds.push('キャンプサイト')
        }
        labels.push(kinds.join('・'))
      }

      // 予約オプション（旧「家グループ」）
      if (!!this.formData.groupKinds && this.formData.groupKinds.length > 0) {
        let kinds = []
        if (this.formData.groupKinds.includes('unlimited')) {
          kinds.push('予約制限なし')
        }
        if (this.formData.groupKinds.includes('limited')) {
          kinds.push('予約制限あり')
        }
        labels.push(kinds.join('・'))
      }

      if (this.formData.isAutoApproved) {
        labels.push('予約承認が不要な家')
      }

      // こだわり
      // if (!!this.formData.coworking) {
      //   labels.push('コワーキングあり')
      // }
      if (this.formData.original) {
        labels.push('オリジナル')
      }
      if (this.formData.yamoriInteraction) {
        labels.push('家守に会える')
      }
      if (this.formData.memberOnly) {
        labels.push('会員専用')
      }
      if (this.formData.deskChair) {
        labels.push('デスク・チェアあり')
      }
      if (this.formData.kitchen) {
        labels.push('キッチンあり')
      }
      if (this.formData.discount || this.formData.discount3Nights || this.formData.discount7Nights) {
        let discountLabel = '連泊割'
        if (this.formData.discount3Nights) {
          discountLabel += '・3泊割'
        }
        if (this.formData.discount7Nights) {
          discountLabel += '・7泊割'
        }
        labels.push(discountLabel)
      }
      if (this.formData.favorite) {
        labels.push('お気に入りのみ')
      }
      if (!!this.formData.parking || !!this.formData.parkingFree || !!this.formData.parkingPrebooking) {
        let parkingLabel = '駐車場あり'
        if (this.formData.parkingFree) {
          parkingLabel += '・無料'
        }
        if (this.formData.parkingPrebooking) {
          parkingLabel += '・事前予約可'
        }
        labels.push(parkingLabel)
      }

      // デフォルト
      if (labels.join('、') === '全国、大人1名') {
        this.searchFieldLabel = 'エリア、日付、人数、こだわりで検索'
        return
      }

      this.searchFieldLabel = labels.join('、')
      this.$emit('update-search-field-label', this.searchFieldLabel)
    },
    updateCheckedAreaPrefs() {
      this.checkedAreas = this.formData.areas
      let prefIds = this.formData.prefs
      this.checkedAreas.forEach((areaId) => {
        const prefs = findPrefs(areaId)
        prefIds = prefIds.concat(prefs.map((pref) => pref.id))
      })
      this.checkedPrefs = prefIds
    },
  },
}
</script>
