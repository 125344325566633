<template>
  <div class="header-tools-icon">
    <button type="button" class="header-menu-item" @click="openMenu()">
      <div :class="showBtnClass">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>

    <nav v-show="showMenu" :class="showClass" style="display: block">
      <div class="header-menu-inner">
        <div class="header-menu-inner-box">
          <div v-if="!user" class="header-menu-content">
            <ul>
              <li class="header-menu-link"><a href="/join">プラン申し込み</a></li>
              <li class="header-menu-link"><a href="/login">ログイン</a></li>
            </ul>
          </div>

          <div v-else-if="user.user_type === 'ticket_plan'" class="header-menu-content">
            <a href="/mypage/profile" class="header-menu-userinfo">
              <div class="header-menu-userinfo-inner">
                <div>
                  <img :src="user.avatar_image" class="header-menu-userinfo-icon" />
                </div>
                <div class="header-menu-userinfo-namearea">
                  <p class="header-menu-userinfo-name">{{ userName() }} さん</p>
                  <div class="header-menu-userinfo-ticket">
                    <p class="ticket-ttl">予約チケット残数</p>
                    <p class="ticket-no">{{ user.tickets }}</p>
                  </div>
                  <div v-if="hasExpiringTicket" class="header-menu-userinfo-expiringticket">
                    <p class="ticket-ttl">うち1か月以内に期限切れ予定</p>
                    <p class="ticket-no">{{ user.expiring_tickets }}</p>
                  </div>
                </div>
              </div>
            </a>
            <a v-if="user.has_upgrade_offer" href="/mypage/plan" class="header-menu-ug">
              <p class="header-menu-ug-icon">
                <svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.75 19.875V17.625H3V9.75C3 8.19375 3.46875 6.81094 4.40625 5.60156C5.34375 4.39219 6.5625 3.6 8.0625 3.225V2.4375C8.0625 1.96875 8.22656 1.57031 8.55469 1.24219C8.88281 0.914062 9.28125 0.75 9.75 0.75C10.2187 0.75 10.6172 0.914062 10.9453 1.24219C11.2734 1.57031 11.4375 1.96875 11.4375 2.4375V3.225C12.9375 3.6 14.1562 4.39219 15.0937 5.60156C16.0312 6.81094 16.5 8.19375 16.5 9.75V17.625H18.75V19.875H0.75ZM9.75 23.25C9.13125 23.25 8.60156 23.0297 8.16094 22.5891C7.72031 22.1484 7.5 21.6187 7.5 21H12C12 21.6187 11.7797 22.1484 11.3391 22.5891C10.8984 23.0297 10.3687 23.25 9.75 23.25ZM5.25 17.625H14.25V9.75C14.25 8.5125 13.8094 7.45312 12.9281 6.57187C12.0469 5.69062 10.9875 5.25 9.75 5.25C8.5125 5.25 7.45312 5.69062 6.57187 6.57187C5.69062 7.45312 5.25 8.5125 5.25 9.75V17.625Z"
                    fill="#6C8F25"
                  />
                </svg>
              </p>
              <p>
                {{ user.first_time_discount_limit }}までプランアップグレードが
                <br class="pc hidden md:block" />
                最大50％オフ
              </p>
            </a>
            <div class="header-menu-accordion">
              <p class="header-menu-accordion-ttl accordion-sp-only" @click="toggleAccordion">会員メニュー</p>
              <ul class="header-menu-accordion-ul accordion-sp-only">
                <li><a href="/mypage/tickets">予約チケット履歴</a></li>
                <li><a href="/billing" data-method="post">支払い方法・履歴</a></li>
                <li><a href="/reviews">レビュー履歴</a></li>
                <li><a href="/logout" data-method="delete">ログアウト</a></li>
              </ul>
            </div>
          </div>

          <div v-else-if="user.user_type === 'legacy_plan' || user.user_type === 'houjin'" class="header-menu-content">
            <user-info-legacy :registrations="registrations" :user="user" />
            <div class="header-menu-accordion">
              <p class="header-menu-accordion-ttl accordion-sp-only" @click="toggleAccordion">会員メニュー</p>
              <ul class="header-menu-accordion-ul accordion-sp-only">
                <li><a href="/mypage/profile">マイページ</a></li>
                <li><a href="/reviews">レビュー履歴</a></li>
                <li><a href="/logout" data-method="delete">ログアウト</a></li>
              </ul>
            </div>
          </div>

          <div v-else-if="user.user_type === 'mail_member'" class="header-menu-content">
            <div class="header-menu-userinfo">
              <div class="header-menu-userinfo-mail">
                <div>
                  <a href="/mypage/profile">
                    <img :src="user.avatar_image" class="header-menu-userinfo-icon" />
                  </a>
                </div>
                <div class="header-menu-userinfo-namearea">
                  <p class="header-menu-userinfo-name">{{ userName() }} さん</p>
                  <p class="header-menu-userinfo-plan">プラン未契約</p>
                  <p class="header-menu-userinfo-applyform">
                    <a href="/join?from=headbtn" class="arrow-r-orange">サービスの申し込み</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="header-menu-accordion">
              <p class="header-menu-accordion-ttl accordion-sp-only" @click="toggleAccordion">会員メニュー</p>
              <ul class="header-menu-accordion-ul accordion-sp-only">
                <li><a href="/mypage/profile">マイページ</a></li>
                <li v-if="user.has_stripe_customer"><a href="/billing" data-method="post">支払い方法・履歴</a></li>
                <li v-if="user.review_count > 0"><a href="/reviews">レビュー履歴</a></li>
                <li><a href="/logout" data-method="delete">ログアウト</a></li>
              </ul>
            </div>
          </div>

          <div v-else-if="user.user_type === 'yamori'" class="header-menu-content">
            <user-info-legacy :registrations="registrations" :user="user" />
            <div class="header-menu-accordion">
              <p class="header-menu-accordion-ttl" @click="toggleAccordion">会員メニュー</p>
              <ul class="header-menu-accordion-ul">
                <li><a href="/mypage/profile">マイページ</a></li>
                <li><a href="/reviews">レビュー履歴</a></li>
                <li><a href="/logout" data-method="delete">ログアウト</a></li>
              </ul>
            </div>
            <div class="header-menu-accordion">
              <p class="header-menu-accordion-ttl" @click="toggleAccordion">家守メニュー</p>
              <ul class="header-menu-accordion-ul">
                <li><a href="/manage">管理画面</a></li>
                <li><a href="/manage/reservations">予約管理</a></li>
                <li><a href="/manage/reviews">レビュー管理</a></li>
                <li><a href="/manage/info">設定</a></li>
                <li>
                  <a href="https://addresslove.notion.site/b1584a7e5e1f4b9492b6e24f75d7afb1" target="_blank" rel="noopener">
                    各種申請・問い合わせ先
                  </a>
                </li>
                <li>
                  <a href="https://addresslove.notion.site/fe599081f1d441728d246601f3ea610d" target="_blank" rel="noopener">
                    運営からのお知らせ
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div v-else-if="user.user_type === 'staff'" class="header-menu-content">
            <user-info-legacy :registrations="registrations" :user="user" />
            <div class="header-menu-accordion">
              <p class="header-menu-accordion-ttl" @click="toggleAccordion">会員メニュー</p>
              <ul class="header-menu-accordion-ul">
                <li><a href="/mypage/profile">マイページ</a></li>
                <li><a href="/reviews">レビュー履歴</a></li>
                <li><a href="/logout" data-method="delete">ログアウト</a></li>
              </ul>
            </div>
            <div v-if="user.has_manageable_home" class="header-menu-accordion">
              <p class="header-menu-accordion-ttl" @click="toggleAccordion">家守メニュー</p>
              <ul class="header-menu-accordion-ul">
                <li><a href="/manage">管理画面</a></li>
                <li><a href="/manage/reservations">予約管理</a></li>
                <li><a href="/manage/reviews">レビュー管理</a></li>
                <li><a href="/manage/info">設定</a></li>
                <li>
                  <a href="https://addresslove.notion.site/b1584a7e5e1f4b9492b6e24f75d7afb1" target="_blank" rel="noopener">
                    各種申請・問い合わせ先
                  </a>
                </li>
                <li>
                  <a href="https://addresslove.notion.site/fe599081f1d441728d246601f3ea610d" target="_blank" rel="noopener">
                    運営からのお知らせ
                  </a>
                </li>
              </ul>
            </div>
            <div class="header-menu-accordion">
              <p class="header-menu-accordion-ttl" @click="toggleAccordion">スタッフメニュー</p>
              <ul class="header-menu-accordion-ul">
                <li><a href="/admin/users">ユーザー管理</a></li>
                <li><a href="/admin/homes">家の管理</a></li>
                <li><a href="/admin/reservations">予約の管理</a></li>
                <li><a href="/admin/reviews">レビューの管理</a></li>
                <li><a href="/admin/surveys">改善提案の管理</a></li>
              </ul>
            </div>
          </div>

          <p class="header-menu-content-ttl">家を探す</p>
          <div class="header-menu-content header-menu-homes">
            <a href="/homes" class="header-menu-homes-web">
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#67635e"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  style="display: inline-block"
                >
                  <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                  <polyline points="9 22 9 12 15 12 15 22"></polyline>
                </svg>
              </p>
              <p>一覧から探す</p>
            </a>
            <div class="header-menu-homes-bottom">
              <a href="/theme_lists" class="header-menu-homes-themelist">
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#67635e"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    style="display: inline-block; margin-bottom: 5px"
                  >
                    <line x1="8" y1="6" x2="21" y2="6"></line>
                    <line x1="8" y1="12" x2="21" y2="12"></line>
                    <line x1="8" y1="18" x2="21" y2="18"></line>
                    <line x1="3" y1="6" x2="3.01" y2="6"></line>
                    <line x1="3" y1="12" x2="3.01" y2="12"></line>
                    <line x1="3" y1="18" x2="3.01" y2="18"></line>
                  </svg>
                </p>
                <p>
                  テーマリスト
                  <br />
                  から探す
                </p>
              </a>
              <a
                href="https://www.google.com/maps/d/u/0/viewer?mid=1_vUzwhGmqPfllWRsw-bbkKxSonuWeLX5&amp;ll=36.94282771370688%2C141.8410115375&amp;z=5"
                target="_blank"
                class="header-menu-homes-map"
              >
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#67635e"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    style="display: inline-block; margin-bottom: 5px"
                  >
                    <circle cx="12" cy="10" r="3" />
                    <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                  </svg>
                </p>
                <p>
                  マップ
                  <br />
                  から探す
                </p>
              </a>
              <a :href="calendarUrl" target="_blank" class="header-menu-homes-list">
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#67635e"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    style="display: inline-block; margin: 2px 0 5px"
                  >
                    <path d="M3 3h18v18H3zM21 9H3M21 15H3M12 3v18" />
                  </svg>
                </p>
                <p>
                  予約状況
                  <br />
                  カレンダー
                </p>
              </a>
            </div>
          </div>
          <p class="header-menu-homes-info">※マップは「新しいタブで開く」と家ページにリンク遷移できます</p>
        </div>
        <div class="header-menu-inner-box">
          <template v-if="!user || user.user_type === 'mail_member'">
            <p class="header-menu-content-ttl">ADDressについて</p>
            <div class="header-menu-content">
              <ul>
                <li class="header-menu-link"><a href="/about">ADDressとは</a></li>
                <li class="header-menu-link"><a href="/plan">料金プラン</a></li>
                <!-- <li class="header-menu-link"><a href="/guide">ADDress初心者ガイド</a></li> -->
                <li class="header-menu-link"><a href="/concerns">不安解消ガイド</a></li>
                <li class="header-menu-link"><a href="/company">運営会社</a></li>
              </ul>
            </div>

            <p class="header-menu-content-ttl">わからないときは</p>
            <div class="header-menu-content">
              <ul>
                <li class="header-menu-link"><a href="https://address.zendesk.com/hc/ja">ヘルプ・お問い合わせ</a></li>
              </ul>
            </div>
          </template>

          <template v-else>
            <p class="header-menu-content-ttl">家を紹介する</p>
            <div class="header-menu-content">
              <ul>
                <li class="header-menu-link">
                  <a href="/theme_lists/new">
                    <p>テーマリストを新規作成</p>
                  </a>
                </li>
              </ul>
            </div>

            <p class="header-menu-content-ttl">ADDressをもっと楽しむ</p>
            <div class="header-menu-content">
              <ul>
                <li class="header-menu-link">
                  <a :href="commmuneUrl" target="_blank" rel="noopener">
                    <p>ADDress+（アドプラ）</p>
                    <p class="header-menu-link-subtxt">会員・家守限定のオンラインコミュニティ</p>
                  </a>
                </li>
              </ul>
            </div>

            <p class="header-menu-content-ttl">サービスについて</p>
            <div class="header-menu-content">
              <ul>
                <li class="header-menu-link">
                  <a href="https://address.commmune.com/view/box?boxId=knowledgeBase001&categoryIndex=99">利用ガイド</a>
                </li>
                <li class="header-menu-link"><a href="https://address-membersupport.zendesk.com/hc/ja">ヘルプ</a></li>
              </ul>
            </div>

            <div class="header-menu-banner">
              <a href="https://sites.google.com/address.love/tomodachisyoukai/" target="_blank" rel="noopener noreferrer">
                <img :src="referralBannerUrl" />
              </a>
            </div>
          </template>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import UserInfoLegacy from './menu/UserInfoLegacy.vue'

export default {
  components: { UserInfoLegacy },
  props: {
    user: {
      type: Object,
      default: null,
    },
    registrations: {
      type: Array,
      default: null,
    },
    stickyNav: {
      type: Boolean,
      required: true,
    },
    commmuneUrl: {
      type: String,
      default: null,
    },
    referralBannerUrl: {
      type: String,
      default: null,
    },
  },
  data: function () {
    return {
      showMenu: false,
      showBtnClass: 'header-menubtn-inner',
      showClass: 'header-menu',
    }
  },
  computed: {
    hasExpiringTicket() {
      return this.user.expiring_tickets > 0
    },
    calendarUrl() {
      if (!!this.user && this.user.is_legacy_plan) {
        return 'https://docs.google.com/spreadsheets/d/19cyKPh-1i2Yf4e011x0ksBvrcQ4mm8Z-VYHgqkOmCTU'
      } else {
        return 'https://docs.google.com/spreadsheets/d/1-F5aQQGEe6khmwm5rBTmto6shlBgb1l2Pl_pSAnItbE'
      }
    },
  },
  methods: {
    openMenu() {
      if (this.showMenu) {
        this.showBtnClass = 'header-menubtn-inner'
        this.showMenu = false
        if (!this.stickyNav) {
          document.getElementById('header_nav').classList.remove('sticky-top')
        }
      } else {
        this.showBtnClass = 'header-menubtn-inner is-open'
        this.showMenu = true
        if (!this.stickyNav) {
          document.getElementById('header_nav').classList.add('sticky-top')
        }
      }
    },
    userName() {
      if (!!this.user && !!this.user.name) {
        return this.user.name
      }
      return '氏名：未登録'
    },
    toggleAccordion(event) {
      const button = event.currentTarget
      if (window.innerWidth < 768) {
        $(button.querySelector('.accordion-sp-only')).toggleClass('active')
        $(button.querySelector('.accordion-sp-only')).next('.header-menu-accordion-ul.accordion-sp-only').slideToggle()
      }
      $(button).toggleClass('active')
      $(button).next('.header-menu-accordion-ul').slideToggle()
    },
  },
}
</script>
