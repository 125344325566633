<template>
  <div class="calender">
    <RoomSearchForm
      ref="roomSearchForm"
      :default-form-data="defaultFormData"
      :cal-start-date="calStartDate"
      :cal-end-date="calEndDate"
      :reserved-dates="reservedDates"
      :holydays="holydays"
      :min-days="minDays"
      :disable-room-count="!enableMultiReserve"
      :is-contracted="isContracted"
      @change-form="setSearchRoomUrl"
    ></RoomSearchForm>

    <div class="btn-shadow btn-calendar" :class="isLegacyPlan ? '' : 'plan2023'">
      <div v-if="isLegacyPlan && !!registrationTickets && registrationTickets.length > 1">
        <div>この家に使えるチケット残数</div>
        <ul class="text-sub mt-2 mb-2">
          <li v-for="registrationTicket in registrationTickets" :key="registrationTicket.id" class="flex">
            <p class="text-gray2">{{ registrationTicket.period_str }}</p>
            <p class="text-bold">{{ registrationTicket.tickets_str }}</p>
          </li>
        </ul>
      </div>

      <div v-else-if="isLegacyPlan && !!registrationTickets && registrationTickets.length === 1">
        <div class="d-flex justify-content-center">
          <p>この家に使えるチケット残数</p>
          <p class="text-bold pl-2">{{ registrationTickets[0]['tickets_str'] }}</p>
        </div>
        <p v-if="!!upgradeOffer">
          <a :href="upgradeOffer.url + '?from=room-search-pc'" target="_blank" rel="noopener" class="btn-text arrow-r-orange">
            {{ upgradeOffer.text }}
          </a>
        </p>
      </div>

      <div v-else-if="isLegacyPlan">
        <div class="d-flex justify-content-center">
          <p>この家に使えるチケット残数</p>
          <p class="text-bold pl-2">なし</p>
        </div>
      </div>

      <div v-else>
        <div class="d-flex justify-content-center align-items-center">
          <p>予約チケット残数</p>
          <p class="text-bold fs-4 pl-2">{{ ticketAmounts }}</p>
        </div>
      </div>

      <div class="flex d-flex justify-content-center">
        <a :href="url" class="btn btn-primary width-full padding-05 mx-3 mt-3">条件に合うお部屋を検索</a>
      </div>
    </div>
  </div>
</template>

<script>
import RoomSearchForm from './RoomSearchForm'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'

export default {
  components: { RoomSearchForm },
  props: {
    defaultFormData: {
      type: Object,
      required: true,
    },
    calStartDate: {
      type: String,
      required: true,
    },
    calEndDate: {
      type: String,
      required: true,
    },
    reservedDates: {
      type: Array,
      default: () => [],
    },
    holydays: {
      type: Array,
      required: true,
    },
    registrationTickets: {
      type: Array,
      default: () => [],
    },
    ticketAmounts: {
      type: Number,
      default: 0,
    },
    upgradeOffer: {
      type: Object,
      default: () => {},
    },
    isSignedIn: {
      type: Boolean,
      required: true,
    },
    isLegacyPlan: {
      type: Boolean,
      required: true,
    },
    isContracted: {
      type: Boolean,
      required: true,
    },
    enableMultiReserve: {
      type: Boolean,
      required: false,
    },
    signupPath: {
      type: String,
      required: true,
    },
    minDays: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      url: null,
    }
  },
  mounted() {
    this.setSearchRoomUrl()
  },
  methods: {
    setSearchRoomUrl: function () {
      this.url = this.$refs.roomSearchForm.getSearchUrl()
      this.url.hash = '#room-calendar'
    },
    formatDate(date) {
      return dayjs(date).format('M/D')
    },
  },
}
</script>
