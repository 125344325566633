<template>
  <div>
    <button class="homedetail-header-share" @click="$modal.show('home-share-modal')">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#67635e"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 8l-5-5-5 5M12 4.2v10.3" />
      </svg>
    </button>
    <Teleport to="body">
      <div class="home-share-modal">
        <modal
          name="home-share-modal"
          height="auto"
          classes="modal-wrapper"
          scrollable
          style="display: flex; align-items: center; justify-content: center; height: 100%"
          @closed="handleClosed"
        >
          <button type="button" class="modal-closebtn" @click.prevent="$modal.hide('home-share-modal')"></button>
          <div class="homedetail-modal">
            <div class="homedetail-modal-content">
              <div class="homedetail-modal-main">
                <h2 class="homedetail-modal-ttl">この家をシェアしましょう</h2>
                <div class="homedetail-modal-body">
                  <div class="homedetail-modal-share">
                    <div class="homedetail-modal-share-btn" @click="copyToClipboard">
                      <p class="homedetail-modal-share-btn-icon"><img :src="copyIconUrl" /></p>
                      <p>URLコピー</p>
                      <span v-show="isCopied" class="homedetail-modal-copy-btn-text">コピーしました</span>
                    </div>
                    <a :href="lineSharaUrl" class="homedetail-modal-share-btn" target="_blank" rel="noopener">
                      <p class="homedetail-modal-share-btn-icon"><img :src="lineIconUrl" /></p>
                      <p>LINEでシェア</p>
                    </a>
                    <a :href="xShareUrl" class="homedetail-modal-share-btn" target="_blank" rel="noopener">
                      <p class="homedetail-modal-share-btn-icon"><img :src="xIconUrl" /></p>
                      <p>Xでシェア</p>
                    </a>
                    <a :href="facebookShareUrl" class="homedetail-modal-share-btn" target="_blank" rel="noopener">
                      <p class="homedetail-modal-share-btn-icon"><img :src="facebookIconUrl" /></p>
                      <p>facebookでシェア</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </modal>
      </div>
    </Teleport>
  </div>
</template>

<script>
import Teleport from 'vue2-teleport'

export default {
  components: { Teleport },
  props: {
    copyIconUrl: {
      type: String,
      required: true,
    },
    lineIconUrl: {
      type: String,
      required: true,
    },
    xIconUrl: {
      type: String,
      required: true,
    },
    facebookIconUrl: {
      type: String,
      required: true,
    },
    shareUrl: {
      type: String,
      required: true,
    },
    shareTitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isCopied: false,
    }
  },
  computed: {
    lineSharaUrl() {
      return `https://social-plugins.line.me/lineit/share?url=${encodeURI(this.shareUrl)}`
    },
    xShareUrl() {
      return `https://twitter.com/intent/tweet?url=${encodeURI(this.shareUrl)}&text=${encodeURI(this.shareTitle)}&via=ADDaddress`
    },
    facebookShareUrl() {
      return `https://www.facebook.com/sharer.php?u=${this.shareUrl}`
    },
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.shareUrl).then(() => (this.isCopied = true))
    },
    handleClosed() {
      this.isCopied = false
    },
  },
}
</script>

<style lang="scss">
.home-share-modal {
  & .vm--modal {
    top: initial !important;
    left: initial !important;
    width: initial !important;
    height: initial !important;
    margin-top: 20px !important;
    background-color: initial !important;
    box-shadow: initial !important;

    @media (width <= 767px) {
      width: 100vw !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  & .vm--block-scroll header {
    z-index: initial !important;
  }

  & .vm--block-scroll .sticky {
    z-index: initial !important;
  }

  .homedetail-modal {
    position: relative;
    overflow-y: scroll;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (width >= 768px) {
      max-height: 90vh;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    @media (width <= 767px) {
      display: block;
      height: 100vh;
    }
  }
}
</style>
